import { MonitoringGetDatePickerMutation } from 'interfaces/graphql.types';

export default function datePickerFilterDocsHelper(
  data: MonitoringGetDatePickerMutation | null | undefined
) {
  if (
    data &&
    data.monitoringGetDatePicker &&
    data.monitoringGetDatePicker.datePicker &&
    data.monitoringGetDatePicker.datePicker.length
  ) {
    const newDatePicker =
      data.monitoringGetDatePicker.datePicker[0].name === 'All Documents'
        ? data.monitoringGetDatePicker.datePicker.slice(2)
        : data.monitoringGetDatePicker.datePicker;

    return {
      monitoringGetDatePicker: {
        ...data.monitoringGetDatePicker,
        datePicker: newDatePicker,
      },
    };
  }

  return data;
}
