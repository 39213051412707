import {
  UserInvestor,
  UserJobResult,
  UserJobResultDetails,
} from 'interfaces/graphql.types';

export type AuthState = {
  user?: {
    isCommunityState?: boolean;
    userId: string;
    licenses?: string[] | null;
    role?: string;
    returningInvestor?: boolean | null;
    isAdvisor?: boolean | null;
    investors?: UserInvestor[] | null;
    activeInvestor?: UserInvestor;
    defaultRoute?: string | null;
    routes?: string[] | null;
  };
  loggedIn?: boolean;
  userJob?: UserJobResultDetails;
  loading?: boolean;
};

export type AuthActionTypes =
  | {
      type: 'LOGIN';
      data:
        | { userJob: UserJobResultDetails }
        | { loggedIn?: boolean }
        | ({
            user: UserJobResult & {
              userId: string;
            };
          } & { loading?: boolean });
    }
  | {
      type: 'LOGOUT';
    }
  | {
      type: 'SETTINGS_USER_INFO_UPDATE';
      data: UserJobResultDetails;
    }
  | {
      type: 'LOGIN_SUCCESS';
    }
  | {
      type: 'LOGIN_REQUEST';
    };

export const INITIAL_STATE: AuthState = {
  user: { userId: '', role: 'USER' },
  loggedIn: false,
  loading: false,
};

const authReducer = (state: AuthState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        ...action.data,
        loggedIn: true,
        loading: false,
      };
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'SETTINGS_USER_INFO_UPDATE':
      return {
        ...state,
        userJob: action.data,
      };
    case 'LOGOUT':
      return {
        user: { userId: '', role: 'USER' },
        loggedIn: false,
        loading: false,
      };
    default:
      return INITIAL_STATE;
  }
};

export default authReducer;
