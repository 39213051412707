import {
  DatePickerType,
  MonitoringGetDatePickerMutation,
  useMonitoringGetDatePickerMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { OptionType } from 'renderer';
import datePickerFilterDocsHelper from 'utils/helpers/datePickerFilterDocsHelper';

const enabledRoutes = [
  '/dashboard',
  '/offering/[id]/investments',
  '/documents',
  '/tax-center',
];

const useInvDatePicker = () => {
  const {
    state: { loggedIn },
  } = useAuth();
  const router = useRouter();

  const [datePicker, setDatePicker] = useState<DatePickerType>();
  const [filteredDatePicker, setFilteredDatePicker] =
    useState<DatePickerType>();

  const [datePickerData, setDatePickerData] = useState<
    MonitoringGetDatePickerMutation | null | undefined
  >();
  const [filteredDatePickerData, setFilteredDatePickerData] = useState<
    MonitoringGetDatePickerMutation | null | undefined
  >();

  const [getDatePicker, { loading: loadingDatePicker = true }] =
    useMonitoringGetDatePickerMutation();
  const isRoute = enabledRoutes.includes(router.pathname);

  // To avoid calling the useMonitoringGetDatePickerMutation on every page, we check the current route and only call it on the necessary pages. This helps optimize performance and prevent unnecessary network requests.
  useEffect(() => {
    if (loggedIn && isRoute) {
      getDatePicker()
        .then((result) => {
          setDatePickerData(result.data);
          const newDatePicked =
            result.data?.monitoringGetDatePicker.datePicker?.[0];
          if (newDatePicked) setDatePicker(newDatePicked);

          // filter out All Documents and divider if present for use on non-document pages
          const filteredResult = datePickerFilterDocsHelper(result.data);

          setFilteredDatePickerData(filteredResult);
          const filteredNewDatePicked =
            filteredResult?.monitoringGetDatePicker.datePicker?.[0];

          if (filteredNewDatePicked)
            setFilteredDatePicker(filteredNewDatePicked);
        })
        .catch(console.error);
    }
  }, [loggedIn, isRoute]);

  const onSelectChangeDatePicker = (datePickerOption?: OptionType | null) => {
    if (!datePickerOption || !datePickerData || !filteredDatePickerData) return;
    if (!filteredDatePickerData.monitoringGetDatePicker.datePicker) return;

    // datePickerData always has all options - set datePicker with passed in option
    setDatePicker({
      __typename: 'DatePickerType',
      name: datePickerOption.key,
      date: datePickerOption.value || '',
    });

    // filteredDatePickerData may not have option (no All Documents), check & set to first option if missing
    const filteredIndex =
      filteredDatePickerData.monitoringGetDatePicker.datePicker.findIndex(
        (opt) =>
          opt.name === datePickerOption.key &&
          opt.date === datePickerOption.value
      );

    const filteredOption =
      filteredDatePickerData.monitoringGetDatePicker.datePicker[
        filteredIndex === -1 ? 0 : filteredIndex
      ];

    setFilteredDatePicker(filteredOption);
  };

  return {
    loadingDatePicker,
    datePicker,
    // setDatePicker,
    datePickerData,
    // setDatePickerData,
    onSelectChangeDatePicker,
    filteredDatePicker,
    filteredDatePickerData,
  };
};

export default useInvDatePicker;
