import {
  DropdownOption,
  MonitoringGetInvestorEntitiesMutation,
  useMonitoringGetInvestorEntitiesMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { OptionType } from 'renderer';

const enabledRoutes = [
  '/dashboard',
  '/offering/[id]/investments',
  '/documents',
  '/tax-center',
];

const useInvestorEntitiesPicker = (fundPk?: string) => {
  const {
    state: { loggedIn },
  } = useAuth();

  const router = useRouter();

  const [investorEntity, setInvestorEntity] = useState<DropdownOption>();
  const [investorEntitiesPickerData, setInvestorEntitiesPickerData] = useState<
    MonitoringGetInvestorEntitiesMutation | null | undefined
  >();

  const [getInvestorEntities, { loading: loadingInvestorEntities }] =
    useMonitoringGetInvestorEntitiesMutation();

  const isRoute = enabledRoutes.includes(router.pathname);

  useEffect(() => {
    if (loggedIn && isRoute) {
      getInvestorEntities({
        variables: {
          data: {
            fundPk,
          },
        },
      })
        .then((result) => {
          setInvestorEntitiesPickerData(result.data);
          const newInvestorEntityPicked =
            result.data?.monitoringGetInvestorEntities.investorEntities?.[0];
          if (newInvestorEntityPicked)
            setInvestorEntity(newInvestorEntityPicked);
        })
        .catch(console.error);
    }
  }, [loggedIn, isRoute, fundPk]);

  const onSelectChangeInvestorEntity = (
    investmentEntityOption?: OptionType | null | undefined
  ) => {
    if (investmentEntityOption) {
      setInvestorEntity({
        __typename: 'DropdownOption',
        name: investmentEntityOption.label,
        pk: investmentEntityOption.value || '',
      });
    }
  };

  return {
    investorEntity,
    investorEntitiesPickerData,
    loadingInvestorEntities,
    setInvestorEntity,
    setInvestorEntitiesPickerData,
    onSelectChangeInvestorEntity,
  };
};

export default useInvestorEntitiesPicker;
