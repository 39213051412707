import { CLIENT, CLIENTS, SANDBOX } from 'config';
import {
  DatafilesType,
  useUtilitiesGetTestDataFilesMutation,
  useUtilitiesRestartBackendServerMutation,
} from 'interfaces/graphql.types';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { OptionType } from 'renderer';
import theme, { solycoTheme } from 'stories/theme';
import { DefaultTheme } from 'styled-components/dist/types';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

interface ClientProvider {
  children: ReactNode;
  handleClientTheme: Dispatch<SetStateAction<DefaultTheme>>;
}

interface ClientContextType {
  clients: OptionType[];
  client: string;
  isLoading: boolean;
  restartTimeLeft: number;
  errorMessage: string;
  isClientSwitchHidden: boolean;
  handleClientChange: (client: string, cb?: () => void) => void;
  handleErrorReset: () => void;
}

const isClientSwitchHidden = !['dev', 'us', 'paris'].includes(SANDBOX);

const RESTART_SERVER_TIME = 30;

const init = {
  clients: [],
  client: '',
  isLoading: false,
  restartTimeLeft: 0,
  errorMessage: '',
  isClientSwitchHidden,
  handleClientChange: () => null,
  handleErrorReset: () => null,
};

export const ClientContext = createContext<ClientContextType>(init);

const ClientProvider = ({ children, handleClientTheme }: ClientProvider) => {
  const [client, setClient] = useState(CLIENT);
  const [clientsFiles, setClientsFiles] = useState<DatafilesType[]>([]);

  useEffect(() => {
    if (client === 'solyco') {
      handleClientTheme(solycoTheme);
    } else {
      handleClientTheme(theme);
    }
  }, [client]);

  const clients = clientsFiles.length
    ? clientsFiles.map((client) => ({
        key: client.folder || '',
        label: client.folder || '',
        value: client.folder?.toLowerCase() || '',
      }))
    : (CLIENTS as string[])?.map((client) => ({
        key: client,
        label: client,
        value: client.toLowerCase(),
      })) || [];

  const [restartTimeLeft, setRestartTimeLeft] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  const [getTestDataFiles, { loading: loadingFiles }] =
    useUtilitiesGetTestDataFilesMutation();

  const [restartServer, { loading: loadingRestartServer }] =
    useUtilitiesRestartBackendServerMutation();

  const handleClientChange = async (client: string, cb?: () => void) => {
    if (isClientSwitchHidden) return;
    try {
      const clientFile = clientsFiles.find(
        (clientFile) => clientFile.folder?.toLowerCase() === client
      );
      const result = await restartServer({
        variables: {
          data: {
            dataFilePath: clientFile?.path || '',
          },
        },
      });
      if (result.data?.utilitiesRestartBackendServer.success) {
        setRestartTimeLeft(RESTART_SERVER_TIME);
        setErrorMessage('');
        if (CLIENTS.includes(client.toLocaleLowerCase())) {
          setClient(client.toLocaleLowerCase());
        } else {
          setClient(CLIENT);
        }
        cb && cb();
      } else {
        const errors = result.data?.utilitiesRestartBackendServer.errors;
        setErrorMessage(formatError(errors).message || '');
      }
    } catch (e) {
      setErrorMessage(formatError(e).message || '');
      formatError(e);
      setSentryErrors(e);
    }
  };

  const handleErrorReset = () => {
    setErrorMessage('');
  };

  useEffect(() => {
    if (isClientSwitchHidden) return;
    const getClientsFiles = async () => {
      try {
        const result = await getTestDataFiles();
        const { datafiles, currentClient, success } =
          result?.data?.utilitiesGetTestDataFiles || {};

        if (success) {
          setClientsFiles(datafiles || []);
          if (
            currentClient &&
            CLIENTS.includes(currentClient.toLocaleLowerCase())
          ) {
            setClient(currentClient.toLocaleLowerCase());
          } else {
            setClient(CLIENT);
          }
        }
      } catch (e) {
        formatError(e);
        setSentryErrors(e);
      }
    };
    getClientsFiles();
  }, []);

  useEffect(() => {
    if (restartTimeLeft === 0) {
      return;
    }
    const timer = setTimeout(() => {
      setRestartTimeLeft(restartTimeLeft - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [restartTimeLeft]);

  return (
    <ClientContext.Provider
      value={{
        clients,
        client,
        isLoading: loadingFiles || loadingRestartServer,
        restartTimeLeft,
        errorMessage,
        isClientSwitchHidden,
        handleClientChange,
        handleErrorReset,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => useContext(ClientContext);

export default ClientProvider;
