import { JWT_ACCESS_TOKEN_EXPIRES_IN } from 'config';
import {
  useGetUserInfoMutation,
  UserInvestor,
  useUserLogoutEverywhereElseMutation,
  useUserLogoutEverywhereMutation,
  useUserLogoutMutation,
  useUserRefreshTokenMutation,
} from 'interfaces/graphql.types';
import authReducer, {
  AuthActionTypes,
  AuthState,
  INITIAL_STATE,
} from 'modules/auth/reducers/authReducer';
import handleIdleUserTimeout from 'modules/auth/utils/handleIdleUserTimeout';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

export const AuthContext = React.createContext<{
  state: AuthState;
  dispatch: React.Dispatch<AuthActionTypes>;
  login(args: { userId: string }, redirectUrl?: string): Promise<void>;
  logout(redirectUrl?: string): void;
  logoutEverywhere(redirectUrl?: string): void;
  logoutEverywhereElse(): void;
  setActiveInvestor: Dispatch<SetStateAction<UserInvestor | undefined>>;
  activeInvestor: UserInvestor | undefined;
}>({
  state: INITIAL_STATE,
  dispatch: () => {
    // default logout parameter value dispatcher
  },
  login: async (_args: { userId: string }, _redirectURL?: string) => {
    // default login parameter value
  },
  logout: () => {
    // default logout parameter value
  },
  logoutEverywhere: () => {
    // default logout parameter value
  },
  logoutEverywhereElse: () => {
    // default logout everywhere else parameter value
  },
  setActiveInvestor: function (
    value: React.SetStateAction<UserInvestor | undefined>
  ): void {
    this.setActiveInvestor(value);
  },
  activeInvestor: undefined,
});

const AuthProvider = ({
  userIsLoggedIn,
  children,
}: {
  children: React.ReactNode;
  userIsLoggedIn: boolean;
}) => {
  const [state, dispatch] = React.useReducer(authReducer, INITIAL_STATE);
  const [userLogout] = useUserLogoutMutation();
  const [userLogoutEverywhere] = useUserLogoutEverywhereMutation();
  const [userLogoutEverywhereElse] = useUserLogoutEverywhereElseMutation();
  const [getUserInfo] = useGetUserInfoMutation();
  const [refreshTokensRequest] = useUserRefreshTokenMutation();

  const router = useRouter();
  const [activeInvestor, setActiveInvestor] = useState<UserInvestor>();

  async function refreshTokens() {
    try {
      const result = await refreshTokensRequest();
      if (
        result.data?.refreshTokens.success &&
        typeof result.data.refreshTokens.userId == 'string'
      ) {
        return result.data.refreshTokens.userId;
      }
      return null;
    } catch (e) {
      formatError(e);
      throw e;
    }
  }

  React.useEffect(
    () =>
      handleIdleUserTimeout(
        Boolean(state.loggedIn),
        logout,
        JWT_ACCESS_TOKEN_EXPIRES_IN ?? 900
      ),
    [state.loggedIn]
  );

  React.useEffect(() => {
    (async function refreshTokensOnMount() {
      if (userIsLoggedIn && !state.loggedIn) {
        try {
          const userId = await refreshTokens();
          if (userId) {
            await login({ userId });
          } else {
            await logout();
          }
        } catch (e) {
          formatError(e);
          await logout();
        }
      }
    })();
  }, []);

  async function login({ userId }: { userId: string }) {
    try {
      dispatch({
        type: 'LOGIN_REQUEST',
      });
      const userInfo = await getUserInfo();
      if (
        userInfo.data?.getUserInfo.success &&
        userInfo.data?.getUserInfo.userJob
      ) {
        dispatch({
          type: 'LOGIN',
          data: {
            userJob: userInfo.data.getUserInfo.userJob,
            user: {
              userId,
              licenses: userInfo.data.getUserInfo.licenses,
              returningInvestor: Boolean(
                userInfo.data.getUserInfo.returningInvestor
              ),
              isAdvisor: Boolean(userInfo.data.getUserInfo.isAdvisor),
              investors: userInfo.data.getUserInfo.investors,
              defaultRoute: userInfo.data.getUserInfo.defaultRoute,
              routes: userInfo.data.getUserInfo.routes,
            },
            loading: false,
          },
        });
        if (userInfo.data?.getUserInfo.defaultRoute && !userIsLoggedIn) {
          router.push(userInfo.data?.getUserInfo.defaultRoute);
        } else if (
          userInfo.data.getUserInfo.defaultRoute &&
          userIsLoggedIn &&
          (router.pathname.includes('/auth') || router.pathname === '/')
        ) {
          router.push(userInfo.data?.getUserInfo.defaultRoute);
        }
      } else {
        setSentryErrors(
          { message: 'Auth Provider user info failed' },
          { other: JSON.stringify(userInfo.data) }
        );
        await logout();
      }
    } catch (e) {
      formatError(e);
      await logout();
    }
  }

  async function logout() {
    try {
      const result = await userLogout();
      if (result.data?.logout.success) {
        setActiveInvestor(undefined);
        dispatch({ type: 'LOGOUT' });
        router.push('/');
      }
    } catch (e) {
      formatError(e);
    }
  }

  async function logoutEverywhere() {
    try {
      const result = await userLogoutEverywhere();
      if (result.data?.logoutEverywhere.success) {
        setActiveInvestor(undefined);
        dispatch({ type: 'LOGOUT' });
      }
    } catch (e) {
      formatError(e);
    }
  }

  async function logoutEverywhereElse() {
    try {
      const result = await userLogoutEverywhereElse();
      if (!result.data?.logoutEverywhereElse.success) {
        formatError(result.data?.logoutEverywhereElse.errors);
      }
    } catch (e) {
      formatError(e);
    }
  }
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        login,
        logout,
        logoutEverywhere,
        logoutEverywhereElse,
        setActiveInvestor,
        activeInvestor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;
