import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { concatPagination, Observable } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { API_ENDPOINT } from 'config';
import { JWT_ACCESS_TOKEN_EXPIRES_IN } from 'config';
import fetch from 'cross-fetch';
import { GraphQLError } from 'graphql';
import { UserRefreshTokenDocument } from 'interfaces/graphql.types';
import { setSentryErrors } from 'utils/helpers/errorHandler';

// global time storage
let time = 0;

const errorAfterware = onError(
  ({
    graphQLErrors,
    networkError,
    response,
    /*, operation, forward*/
  }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
        setSentryErrors(
          {
            error: graphQLErrors,
            message,
          },
          { other: JSON.stringify(response) }
        );
      });
    }
    if (networkError) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(`[Network error]: ${networkError}`);
      }
    }

    if (networkError) {
      const errorCode = (networkError as any)?.statusCode;
      const errorMessage = `Error: ${errorCode} - Something went wrong`;

      setSentryErrors(
        {
          error: networkError,
          errorMessage,
          errorCode,
        },
        { other: JSON.stringify(response) }
      );

      return new Observable((observer) => {
        const error = {
          message: errorMessage,
        };
        observer.next({
          errors: [error as GraphQLError],
        });
        observer.complete();
      });
    }
  }
);

const gqlLoginOperations = [
  'SigninCheckMfa',
  'UserRefreshToken',
  'UtilitiesGetTestDataFiles',
  /* @TODO: add any other operations where user is logged in */
];

function refreshTokenRequest(apolloClient: ApolloClient<any>) {
  const options = { mutation: UserRefreshTokenDocument };
  return apolloClient.mutate(options);
}

const logoutAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    if (response.data) {
      const doRedirect = Object.keys(response.data).reduce((prevValue, key) => {
        if (prevValue) return prevValue;
        const code = response.data?.[key]?.errors?.[0]?.code;
        const message = response.data?.[key]?.errors?.[0]?.message;
        return (
          /* user not connected */
          (code == 20 &&
            !gqlLoginOperations.includes(operation.operationName)) ||
          (message == 'invalid_refresh_token' &&
            operation.operationName !== 'UserRefreshToken')
        );
      }, false);
      if (doRedirect) {
        window.location.replace('/');
      }
    }
    return response;
  })
);

const uploadLink = createUploadLink({
  uri: API_ENDPOINT,
  credentials: 'include',
  fetch,
}) as unknown as ApolloLink;

const updateTimerAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    if (
      gqlLoginOperations.includes(operation.operationName) &&
      response.data?.[Object.keys(response.data || {})?.[0]]?.success == true
    ) {
      const startDate = new Date();
      time = startDate.getTime();
    } else if (operation.operationName == 'UserLogout') {
      time = 0;
    }
    return response;
  })
);

const tokenRefreshLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((operation) => {
          if (
            operation.operationName &&
            gqlLoginOperations.includes(operation.operationName)
          )
            return;

          const startDate = new Date(time);
          if (
            startDate &&
            time !== 0 &&
            !Number.isNaN(startDate.getTime()) &&
            (new Date().getTime() - startDate.getTime()) / 1000 >=
              JWT_ACCESS_TOKEN_EXPIRES_IN
          ) {
            const apolloClient = initializeApollo();
            return refreshTokenRequest(apolloClient).then((result) => {
              const tokenRefreshed = Boolean(
                result.data?.refreshTokens.success
              );
              if (tokenRefreshed) {
                const startDate = new Date();
                time = startDate.getTime();
              }
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const link = logoutAfterware.concat(
  errorAfterware.concat(
    tokenRefreshLink.concat(updateTimerAfterware.concat(uploadLink))
  )
);

function createApolloClient(ssrMode?: boolean) {
  return new ApolloClient({
    ssrMode: ssrMode,
    link: ssrMode
      ? createHttpLink({
          uri: `${process.env.MIDDLETIER_URL}/graphql`,
          credentials: 'include',
        })
      : link,
    credentials: 'include',
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            all: concatPagination(),
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState?: null, ssrMode?: boolean) {
  const _apolloClient = createApolloClient(ssrMode);

  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  return _apolloClient;
}

export function useApollo(initialState?: null, ssrMode?: boolean) {
  const store = initializeApollo(initialState, ssrMode);
  return store;
}
